<template>
  <div>
    <v-row>
      <v-col cols="6" sm="3">
        <v-select
          v-model="question.type"
          dense
          filled
          :items="types"
          label="نوع السؤال"
          item-text="text"
          item-value="value"
          @click="$emit('select_input', { prop: 'type', value: question.type })"
          @blur="$emit('blur_input', { prop: 'type', value: question.type })"
          @change="
            $emit('select_changed', { prop: 'type', value: question.type })
          "
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="3" v-if="question.type == 'explain'">
        <v-select
          v-model="question.input_type"
          dense
          filled
          :items="input_types"
          label="نوع الحقل"
          item-text="text"
          item-value="value"
          @click="
            $emit('select_input', {
              prop: 'input_type',
              value: question.input_type,
            })
          "
          @blur="
            $emit('blur_input', {
              prop: 'input_type',
              value: question.input_type,
            })
          "
          @change="
            $emit('select_changed', {
              prop: 'input_type',
              value: question.input_type,
            })
          "
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="2" v-if="question.type == 'mcq'">
        <v-text-field
          v-model="question.max_answers"
          dense
          filled
          @focus="
            $emit('select_input', {
              prop: 'max_answers',
              value: question.max_answers,
            })
          "
          @blur="
            $emit('blur_input', {
              prop: 'max_answers',
              value: question.max_answers,
            })
          "
          label="الحد الأقصي للأجوبة"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="2"
        v-if="question.type == 'explain' && question.input_type == 'number'"
      >
        <v-text-field
          v-model="question.max_value"
          dense
          filled
          label="الحد الأقصي للقيمة"
          @focus="
            $emit('select_input', {
              prop: 'max_value',
              value: question.max_value,
            })
          "
          @blur="
            $emit('blur_input', {
              prop: 'max_value',
              value: question.max_value,
            })
          "
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="2">
        <v-switch
          v-model="question.related_with_question"
          label="متعلق بسؤال"
          color="primary"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Settings",
  props: ["question"],
  data() {
    return {
      types: [
        {
          value: "mcq",
          text: "اختيار من متعدد",
        },
        {
          value: "explain",
          text: "شرح",
        },
      ],
      input_types: [
        {
          value: "text",
          text: "نص",
        },
        {
          value: "number",
          text: "رقم",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch {
    background: #f0f0f0;
    height: 53px;
    margin-top: 0;
    padding: 18px 8px 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom: 1px solid #202020;
  }
}
</style>
