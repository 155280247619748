import { render, staticRenderFns } from "./RelatedQuestion.vue?vue&type=template&id=91e431e2&scoped=true&"
import script from "./RelatedQuestion.vue?vue&type=script&lang=js&"
export * from "./RelatedQuestion.vue?vue&type=script&lang=js&"
import style0 from "./RelatedQuestion.vue?vue&type=style&index=0&id=91e431e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91e431e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VCol,VRow,VSelect,VTextField})
