<template>
  <div>
    <v-card
      class="question"
      v-for="(q, i) in form.questions"
      :key="i"
      elevation="0"
      @click.native="q_selected(q)"
    >
      <v-text-field
        :rules="[(v) => !!v || 'حقل مطلوب']"
        v-model="q.text"
        dense
        class="question_text"
        filled
        @focus="select_input('text', q.text, q.id)"
        @blur="blur_input('text', q.text, q.id, i)"
      >
        <template slot="label">
          <strong class="red--text">*</strong>
          السؤال
        </template>
        <template slot="prepend">
          <v-chip color="black" label class="white--text">{{ q.sort }}</v-chip>
        </template>
        <template slot="append-outer">
          <div class="d-flex ma-2">
            <v-file-input
              class="file"
              hide-input
              prepend-icon="cloud_upload"
              v-model="q.image"
              @change="image_changed('image', q.image, q.id, i)"
            >
            </v-file-input>
            <div class="mx-3">
              <v-btn
                @click="sort_q(q, 'up', i)"
                v-if="i != 0"
                height="20px"
                class="mb-1"
              >
                <v-icon>expand_less</v-icon>
              </v-btn>
              <v-btn
                v-if="form.questions.length != 1"
                @click="sort_q(q, 'down', i)"
                height="20px"
              >
                <v-icon>expand_more</v-icon>
              </v-btn>
            </div>
            <v-btn @click="delete_q(q.id, i)" color="red" height="46">
              <v-icon color="white">delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-text-field>
      <v-card-text class="pa-0 d-none" :class="{ 'd-block': q.focused }">
        <Settings
          @select_input="
            ($event) => select_input($event.prop, $event.value, q.id)
          "
          @blur_input="
            ($event) => blur_input($event.prop, $event.value, q.id, i)
          "
          @select_changed="
            ($event) => blur_input($event.prop, $event.value, q.id, i)
          "
          :question="q"
        ></Settings>
        <RelatedQuestion
          v-if="q.related_with_question"
          :questions="form.questions"
          :q="q"
        ></RelatedQuestion>
        <div v-if="q.type == 'mcq'">
          <Mcq
            :ref="`mcq_${option.id}`"
            :q="q"
            :questions="form.questions"
            :option="option"
            v-for="(option, x) in q.options"
            :key="x"
            @delete_option="($event) => delete_option($event.id, i, x)"
            :mcq_index="x"
            :q_index="i"
            @update_switches="update_switches($event)"
          ></Mcq>

          <v-btn large class="mb-5" @click="new_option(q.id, i)"
            >اختيار جديد</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Settings from "./setting";
import Questions from "./questions";

import RelatedQuestion from "./RelatedQuestion";
import Mcq from "./mcq";

export default {
  name: "Questions",
  props: ["form"],
  data() {
    return {
      selected_input: null,
    };
  },

  computed: {
    q_select: (app) => (id, ind) => {
      let selects = app.form.questions;
      selects[ind].disabled = true;
      return selects;
    },
  },
  components: {
    Settings,
    Mcq,
    Questions,
    RelatedQuestion,
  },
  methods: {
    sort_position(sort_type, question_index, remove_q_related, remover_related_answer) {
      let currItem = this.form.questions.splice(question_index, 1);

      let position =
        sort_type == "up" ? question_index - 1 : question_index + 1;
      this.form.questions.splice(position, 0, currItem[0]);

      if (remove_q_related) {
        this.form.questions[position].related.related_id = null;
        this.form.questions[position].related_with_question = false;
      }
      if(remover_related_answer.length != 0) {
        remover_related_answer.forEach(e => {
          let option = this.form.questions[position].options.find((op) => op.id == e);
          option.related.q_id = null;
          option.related.options = [];
          option.related_with_question = false;
        });
      }
    },
    sort_q(q, type, i) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/sort_q`,
            method: "post",
          },
          data: {
            type: type,
            q_id: q.id,
            options: q.options
          },
        })
        .then((res) => {
          let remove_q_related = res.data.data.remove_q_related;
          let remover_related_answer = res.data.data.remover_related_answer;
          console.log(remover_related_answer);
          this.sort_position(type, i, remove_q_related,remover_related_answer);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    update_switches(ev) {
      console.log(
        this.$refs[`mcq_${ev}`][0].$refs[`re_answer_${ev}`].set_witches()
      );
    },
    sort_questions(ind) {
      this.form.questions.splice(ind, 1);
      this.form.questions.forEach((e, i) => {
        e.sort = i + 1;
      });
    },
    delete_q(id, ind) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/${this.$route.params.id}/${id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.$emit("update_questions");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    check_input(new_value) {
      if (new_value != this.selected_input.value) {
        if (new_value == "") {
          return true;
        }
        return false;
      }
      return true;
    },
    q_selected(q) {
      this.form.questions.forEach((e) => {
        e.focused = false;
      });
      q.focused = true;
    },
    select_input(prop, value, id) {
      this.selected_input = {
        prop: prop,
        value: value,
        id: id,
      };
    },
    blur_input(prop, new_value, id, index) {
      if (this.selected_input) {
        if (!this.check_input(new_value)) {
          this.update_q(prop, new_value, id, index);
        }
      }
      this.$nextTick(() => {
        this.selected_input = null;
      });
    },
    image_changed(prop, new_value, id, index) {
      this.update_q(prop, new_value, id, index);
    },
    removeOptionRelated(id) {
      this.form.questions.forEach((e, i) => {
        if (e.related.related_id == id) {
          e.related.options = [];
        }
      });
    },
    update_q(prop, new_value, id, index) {
      const formData = new FormData();
      formData.append(prop, new_value);
      formData.append("id", id);
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/update_q`,
            method: "POST",
          },
          data: formData,
        })
        .then((res) => {
          if (prop != "image") {
            this.form.questions[prop] = res.data.data[prop];
            if (prop == "type" && new_value == "explain") {
              this.removeOptionRelated(id);
            }
          } else {
            this.form.questions[index]["image"] = null;
            this.form.questions[index]["image_path"] =
              res.data.data["image_path"];
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    new_option(q_id, q_index) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/create_option/${q_id}`,
            method: "POST",
          },
        })
        .then((res) => {
          this.$set(
            this.form.questions[q_index].options,
            this.form.questions[q_index].options.length,
            res.data.data
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_option(id, q_index, option_index) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/delete_option/${id}`,
            method: "POST",
          },
        })
        .then((res) => {
          this.form.questions[q_index].options.splice(option_index, 1);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field--enclosed .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer {
    margin-top: 3px !important;
  }
  .v-chip.v-size--default {
    font-size: 20px;
    height: 51px;
    width: 41px;
    text-align: center;
    padding: 0;
    display: block;
  }
  .file {
    .v-input__prepend-outer {
      position: relative;
      top: -10px;
      button {
        font-size: 35px;
      }
    }
  }
  .question:last-of-type {
    margin-bottom: 100px;
  }

  .form_btns {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
