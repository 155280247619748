var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"dense":"","filled":"","items":_vm.types,"label":"نوع السؤال","item-text":"text","item-value":"value"},on:{"click":function($event){return _vm.$emit('select_input', { prop: 'type', value: _vm.question.type })},"blur":function($event){return _vm.$emit('blur_input', { prop: 'type', value: _vm.question.type })},"change":function($event){return _vm.$emit('select_changed', { prop: 'type', value: _vm.question.type })}},model:{value:(_vm.question.type),callback:function ($$v) {_vm.$set(_vm.question, "type", $$v)},expression:"question.type"}})],1),(_vm.question.type == 'explain')?_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"dense":"","filled":"","items":_vm.input_types,"label":"نوع الحقل","item-text":"text","item-value":"value"},on:{"click":function($event){return _vm.$emit('select_input', {
            prop: 'input_type',
            value: _vm.question.input_type,
          })},"blur":function($event){return _vm.$emit('blur_input', {
            prop: 'input_type',
            value: _vm.question.input_type,
          })},"change":function($event){return _vm.$emit('select_changed', {
            prop: 'input_type',
            value: _vm.question.input_type,
          })}},model:{value:(_vm.question.input_type),callback:function ($$v) {_vm.$set(_vm.question, "input_type", $$v)},expression:"question.input_type"}})],1):_vm._e(),(_vm.question.type == 'mcq')?_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"الحد الأقصي للأجوبة"},on:{"focus":function($event){return _vm.$emit('select_input', {
            prop: 'max_answers',
            value: _vm.question.max_answers,
          })},"blur":function($event){return _vm.$emit('blur_input', {
            prop: 'max_answers',
            value: _vm.question.max_answers,
          })}},model:{value:(_vm.question.max_answers),callback:function ($$v) {_vm.$set(_vm.question, "max_answers", $$v)},expression:"question.max_answers"}})],1):_vm._e(),(_vm.question.type == 'explain' && _vm.question.input_type == 'number')?_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"الحد الأقصي للقيمة"},on:{"focus":function($event){return _vm.$emit('select_input', {
            prop: 'max_value',
            value: _vm.question.max_value,
          })},"blur":function($event){return _vm.$emit('blur_input', {
            prop: 'max_value',
            value: _vm.question.max_value,
          })}},model:{value:(_vm.question.max_value),callback:function ($$v) {_vm.$set(_vm.question, "max_value", $$v)},expression:"question.max_value"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-switch',{attrs:{"label":"متعلق بسؤال","color":"primary"},model:{value:(_vm.question.related_with_question),callback:function ($$v) {_vm.$set(_vm.question, "related_with_question", $$v)},expression:"question.related_with_question"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }