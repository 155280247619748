<template>
  <div>
    <v-card color="#f0f0f0" class="mb-5" elevation="0">
      <v-card-text>
        <v-select
          :items="select_q"
          v-model="q.related.related_id"
          filled
          dense
          item-value="id"
          item-text="text"
          @click="
            select_input(
              'related_id',
              q.related.related_id,
              q.related.related_id,
              q.id
            )
          "
          @blur="
            blur_input(
              'related_id',
              q.related.related_id,
              q.related.related_id,
              q.id
            )
          "
          @change="
            blur_input(
              'related_id',
              q.related.related_id,
              q.related.related_id,
              q.id
            )
          "
        >
        </v-select>
        <v-select
          v-if="related_q.id && related_q.type == 'mcq'"
          filled
          dense
          multiple
          label="اختيار الاجوبة"
          item-text="text"
          item-value="id"
          :items="related_q.options"
          v-model="q.related.options"
          @click="
            select_input(
              'options',
              q.related.options,
              q.related.related_id,
              q.id
            )
          "
          @change="
            select_changed(
              'options',
              q.related.options,
              q.related.related_id,
              q.id
            )
          "
        ></v-select>

        <v-row v-if="related_q.id && related_q.type == 'explain'">
          <v-col cols="8">
            <v-text-field
              v-model="q.related.value"
              @focus="
                select_input(
                  'value',
                  q.related.value,
                  q.related.related_id,
                  q.id
                )
              "
              @blur="
                blur_input('value', q.related.value, q.related.related_id, q.id)
              "
              filled
              dense
              label="القيمة"
              :rules="[(v) => !!v || 'حقل مطلوب']"
              :type="related_q.input_type"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            v-if="related_q.id && related_q.input_type == 'number'"
          >
            <v-text-field
              v-model="q.related.greater_than"
              @focus="
                select_input(
                  'greater_than',
                  q.related.greater_than,
                  q.related.related_id,
                  q.id
                )
              "
              @blur="
                blur_input(
                  'greater_than',
                  q.related.greater_than,
                  q.related.related_id,
                  q.id
                )
              "
              filled
              dense
              type="number"
              label="اكبر من"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            v-if="related_q.id && related_q.input_type == 'number'"
          >
            <v-text-field
              v-model="q.related.less_than"
              @focus="
                select_input(
                  'less_than',
                  q.related.less_than,
                  q.related.related_id,
                  q.id
                )
              "
              @blur="
                blur_input(
                  'less_than',
                  q.related.less_than,
                  q.related.related_id,
                  q.id
                )
              "
              filled
              dense
              type="number"
              label="اصغر من"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Questions",
  props: ["q", "questions"],
  data() {
    return {
      selected_input: null,
    };
  },
  computed: {
    related_q() {
      let find_related;

      if (this.q.related.related_id) {
        find_related = this.questions.find(
          (e) => e.id == this.q.related.related_id
        );
      } else {
        find_related = this.q.related;
      }

      return find_related;
    },
    select_q() {
      let questions = JSON.parse(
        JSON.stringify(this.questions.filter((e) => e.id != this.q.id))
      );
      let index_q = this.questions.findIndex((e) => e.id == this.q.id);
      questions.forEach((e, i) => {
        if (i + 1 > index_q) {
          questions.splice(i, 1);
        }
      });
      return questions;
    },
  },
  methods: {
    check_input(new_value) {
      if (new_value != this.selected_input.value) {
        return false;
      }
      return true;
    },
    select_input(prop, value, related_id, q_id) {
      this.selected_input = {
        prop: prop,
        value: value,
        q_id: q_id,
        related_id: related_id,
      };
    },

    select_changed(prop, new_value, related_id, q_id) {
      this.update_q(prop, new_value, related_id, q_id);
    },
    blur_input(prop, new_value, related_id, q_id) {
      if (this.selected_input) {
        if (!this.check_input(new_value, prop)) {
          this.update_q(prop, new_value, related_id, q_id);
        }
      }
      this.$nextTick(() => {
        this.selected_input = null;
      });
    },
    update_q(prop, new_value, related_id, q_id) {
      const formData = new FormData();

      if (prop == "options") {
        new_value.forEach((e, i) => {
          formData.append(`${prop}[${i}]`, e);
        });
      } else {
        formData.append(prop, new_value);
      }
      formData.append("related_id", related_id);
      formData.append("q_id", q_id);
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/update_related`,
            method: "POST",
          },
          data: formData,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field--enclosed .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer {
    margin-top: 3px !important;
  }
  .v-chip.v-size--default {
    font-size: 20px;
    height: 51px;
    width: 41px;
    text-align: center;
    padding: 0;
    display: block;
  }
  .file {
    .v-input__prepend-outer {
      position: relative;
      top: -10px;
      button {
        font-size: 35px;
      }
    }
  }
  // .question:last-of-type {
  //   margin-bottom: 100px;
  // }

  .form_btns {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
