<template>
  <div>
    <v-card color="blue lighten-5" class="mb-5" elevation="0">
      <v-card-text>
        <v-select
          filled
          dense
          item-value="id"
          item-text="text"
          :items="select_q"
          v-model="option.related.q_id"
          @change="related_answer(option)"
        >
        </v-select>

        <div v-if="related_q.type == 'mcq'">
          <v-row v-for="(op, i) in related_q.options" :key="i">
            <v-col cols="4">
              <v-text-field
                hide-details
                :value="op.text"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="switches[i]"
                @change="related_with_q($event, op, i)"
                hide-details
                label="السعر متعلق بالكمية"
                :input-value="relate_quantity(op)"
              ></v-switch>
            </v-col>

            <v-col cols="4">
              <v-text-field
                :value="option_price(op.id).option_price"
                hide-details
                filled
                :disabled="!-option_price(op.id).id"
                @blur="
                  blur_input(
                    $event,
                    option_price(op.id).option_price,
                    option_price(op.id)
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "RelatedAnswer",
  props: ["questions", "q", "option"],
  data() {
    return {
      switches: [],
    };
  },

  computed: {
    option_price: function (app) {
      return function (option_id) {
        let price = 0;
        let find = app.option.related.options_data.find(
          (e) => e.id == option_id
        );
        if (find) {
          return (price = find);
        }
        return {
          option_price: price,
        };
      };
    },
    relate_quantity: (app) => (related_q_option) => {
      let find_re_op;
      if (app.option.related.options) {
        find_re_op = app.option.related.options.find(
          (e) => e == related_q_option.id
        );
      }
      return !!find_re_op;
    },
    related_q() {
      let find_related;
      if (this.option.related.q_id) {
        find_related = this.questions.find(
          (e) => e.id == this.option.related.q_id
        );
      } else {
        find_related = {};
      }

      return find_related;
    },
    select_q() {
      let questions = JSON.parse(
        JSON.stringify(this.questions.filter((e) => e.id !== this.q.id))
      );
      let index_q = this.questions.findIndex((e) => e.id == this.q.id);
      questions.forEach((e, i) => {
        if (i + 1 > index_q) {
          questions.splice(i, 1);
        } else if (e.type == "explain" && e.input_type == "text") {
          questions.splice(i, 1);
        } else if (e.type == "mcq") {
          let is_num = false;
          for (let x = 0; x < e.options.length; x++) {
            if (!isNaN(e.options[x].text)) {
              is_num = true;
              break;
            }
          }
          if (!is_num) {
            questions.splice(i, 1);
          }
        }
      });
      return questions;
    },
  },

  methods: {
    set_witches() {
      if (this.related_q.id != undefined) {
        this.related_q.options.forEach((ev, i) => {
          if (
            this.option.related.options.find((e) => e == ev.id) != undefined
          ) {
            this.switches[i] = true;
          } else {
            this.switches[i] = false;
          }
        });
      }
    },
    related_with_q(ev, option, switch_index) {
      if (ev) {
        if (isNaN(option.text)) {
          this.$nextTick(() => {
            this.switches[switch_index] = false;
          });
        } else {
          let find_id = this.option.related.options.findIndex(
            (e) => e == option.id
          );
          if (find_id != -1) {
            this.option.related.options.splice(find_id, 1);
            this.option.related.options_data.splice(find_id, 1);
          } else {
            this.option.related.options.push(option.id);
            this.option.related.options_data.push({
              id: option.id,
              option_price: 0,
              quantity: option.text,
              related_price: 0,
            });
          }
        }
      } else {
        let find_id = this.option.related.options.findIndex(
          (e) => e == option.id
        );
        this.option.related.options.splice(find_id, 1);
        this.option.related.options_data.splice(find_id, 1);
      }
      this.related_answer(this.option);
    },
    related_answer(option) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/related_answer`,
            method: "POST",
          },
          data: {
            option_id: option.id,
            q_id: option.related.q_id,
            options: option.related.options,
            options_data: option.related.options_data,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    blur_input(new_val, old_val, opt_data) {
      if (new_val.target.value != old_val && opt_data.id) {
        opt_data.option_price = new_val.target.value;
        this.$nextTick(() => {
          this.related_answer(this.option);
        });
      }
    },
  },
  created() {
    this.set_witches();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field--enclosed .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer {
    margin-top: 3px !important;
  }
  .v-chip.v-size--default {
    font-size: 20px;
    height: 51px;
    width: 41px;
    text-align: center;
    padding: 0;
    display: block;
  }
  .file {
    .v-input__prepend-outer {
      position: relative;
      top: -10px;
      button {
        font-size: 35px;
      }
    }
  }

  .form_btns {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .v-input--switch {
    background: #d6e4ee !important;
    height: 56px !important;
    margin-top: 0;
    padding: 18px 8px 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom: 1px solid #838383;
  }
}
</style>
