<template>
  <v-container>
    <v-row v-if="!form_loaded">
      <v-col v-for="i in 2" :key="i" cols="6" sm="3">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
    </v-row>

    <div v-if="!form_loaded">
      <v-row v-for="x in 5" :key="x">
        <v-col v-for="i in 4" :key="i" :cols="i == 2 ? '5' : '2'">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-form ref="form" v-if="form_loaded">
      <BasicIno :form="form"></BasicIno>
      <Questions @update_questions="get_form()" :form="form"></Questions>
      <v-footer
        :style="{ width: `calc(100% - ${footer_width}px)` }"
        left
        fixed
        bottom
        class="pa-0"
        color="#f0f0f0"
      >
        <v-container class="d-flex align-center justify-space-between">
          <v-btn
            v-if="form.type == 'bank'"
            color="error"
            x-large
            @click="delete_dialog = true"
            >حذف النموذج</v-btn
          >
          <v-btn x-large @click="new_question()">سؤال جديد</v-btn>
        </v-container>
      </v-footer>
    </v-form>

    <v-dialog max-width="300" persistent :value="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block">حذف النموذج ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_form()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BasicIno from "@/components/forms/base";
import Questions from "@/components/forms/questions/questions";

export default {
  name: "setQuestions",
  data() {
    return {
      footer_width: 201,
      form_loaded: false,
      delete_dialog: false,
      delete_loader: false,
      form: {
        id: 1,
        name: "asdas",
        status: 1,
        questions: [],
      },
    };
  },
  components: {
    BasicIno,
    Questions,
  },

  watch: {
    "$store.state.menu"(e) {
      if (!e) {
        this.footer_width = 0;
      } else {
        this.footer_width = 0;
      }
    },
  },
  computed: {},

  methods: {
    new_question() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/create_answer/${this.$route.params.id}`,
            method: "post",
          },
        })
        .then((res) => {
          this.$set(
            this.form.questions,
            this.form.questions.length,
            res.data.data
          );
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_form() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          console.log(res);
          this.form = Object.assign({}, res.data);
          this.$nextTick(() => {
            this.form_loaded = true;
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_form() {
      if (this.form.type == "bank" && this.delete_loader == false) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `forms/${this.form.id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.$router.push("/forms");
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },

  mounted() {
    this.get_form();
  },
};
</script>

<style lang="scss" scoped></style>
