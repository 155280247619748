<template>
  <v-card class="mb-10">
    <v-card-title>
      <h4>البيانات الساسية</h4>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" sm="4">
          <v-text-field
            @focus="select_input('form_name', form.name)"
            @blur="blur_input('form_name', form.name)"
            :rules="[(v) => !!v || 'حقل مطلوب']"
            filled
            dense
            v-model="form.name"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              اسم النموذج
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4">
          <v-select
            filled
            dense
            v-model="form.status"
            label="حالة النموذج"
            :items="status"
            @click="select_input('status', form.status)"
            @blur="blur_input('status', form.status)"
            @change="blur_input('status', form.status)"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BasicInfo",
  props: ["form"],
  data() {
    return {
      selected_input: null,
      status: [
        {
          value: 0,
          text: "غير فعال",
        },
        {
          value: 1,
          text: "فعال",
        },
      ],
    };
  },
  methods: {
    check_input(new_value) {
      if (new_value != this.selected_input.value) {
        return false;
      }
      return true;
    },
    select_input(prop, value) {
      this.selected_input = {
        prop: prop,
        value: value,
      };
    },
    blur_input(prop, new_value) {
      if (this.selected_input) {
        console.log(this.check_input(new_value));
        if (!this.check_input(new_value)) {
          this.update_form(prop, new_value);
        }
      }
      this.$nextTick(() => {
        this.selected_input = null;
      });
    },
    update_form() {
      console.log('update')
      this.$store.dispatch("public__request", {
        config: {
          url: `forms/update_form/${this.form.id}`,
          method: "post",
        },
        data: {
          form_id: this.form.id,
          status: this.form.status,
          name: this.form.name,
        },
      }).then(res=> {
        console.log(res);
      })
    },
  },
};
</script>
