<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-text-field
          :rules="[(v) => !!v || 'حقل مطلوب']"
          v-model="option.text"
          dense
          class="question_text"
          filled
          @focus="select_input('text', option.text, option.id)"
          @blur="blur_input('text', option.text, option.id, mcq_index)"
        >
          <template slot="label">
            <strong class="red--text">*</strong>
            الجواب
          </template>
          <template slot="prepend">
            <v-chip label>1</v-chip>
          </template>
          <template slot="append">
            <v-file-input
              @change="image_changed('image', option.image, option.id)"
              class="file"
              hide-input
              prepend-icon="cloud_upload"
              v-model="option.image"
            >
            </v-file-input>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="2">
        <v-switch
          v-model="option.related_with_question"
          dense
          hide-details
          label="الاجابة متعلق بسؤال"
          @change="option_related($event, option)"
        ></v-switch>
      </v-col>

      <v-col cols="2">
        <v-text-field
          height="62"
          dens
          label="السعر"
          filled
          :disabled="option.related_with_question"
          v-model="option.price"
          @focus="select_input('price', option.price, option.id)"
          @blur="blur_input('price', option.price, option.id, mcq_index)"
        >
        </v-text-field>
      </v-col>

      <v-col cols="4">
        <div class="d-flex">
          <div class="me-2">
            <v-btn
              @click="sort_option('up')"
              v-if="mcq_index != 0"
              height="20px"
              class="mb-1 d-block"
            >
              <v-icon>expand_less</v-icon>
            </v-btn>
            <v-btn @click="sort_option('down')" height="20px">
              <v-icon>expand_more</v-icon>
            </v-btn>
          </div>
          <v-btn
            v-if="mcq_index != 0"
            @click="$emit('delete_option', { id: option.id })"
            color="red"
            height="46"
          >
            <v-icon color="white">delete</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <RelatedAnswer
      :ref="`re_answer_${option.id}`"
      :q="q"
      :questions="questions"
      :option="option"
      :change_switches="change_switches"
      v-show="option.related_with_question"
    ></RelatedAnswer>
  </div>
</template>

<script>
import RelatedAnswer from "./RelatedAnswer.vue";
export default {
  
  props: ["option", "q", "q_index", "questions", "mcq_index"],
  name: "Mcq",
  data() {
    return {
      selected_input: null,
      change_switches: false,
    };
  },
  methods: {
    option_related(ev, option) {
      if (option.related.q_id) {
        this.update_option("option_related", ev, option.id);
      }
    },
    check_value(value, id) {
      if (isNaN(value)) {
        this.questions.forEach((e, i) => {
          e.options.forEach((op, x) => {
            if (op.related.q_id == this.q.id) {
              let op_id = op.related.options.findIndex((e) => e == id);
              this.$set(op.related.options, op_id, null);
              this.$nextTick(() => {
                this.$emit("update_switches", op.id);
              });
            }
          });
        });
      }
    },
    check_input(new_value) {
      if (new_value != this.selected_input.value) {
        if (new_value == "") {
          return true;
        }
        return false;
      }
      return true;
    },
    select_input(prop, value, id) {
      this.selected_input = {
        prop: prop,
        value: value,
        id: id,
      };
    },
    blur_input(prop, new_value, id, index) {
      if (this.selected_input) {
        if (!this.check_input(new_value)) {
          this.update_option(prop, new_value, id, index);
        }
      }
      this.$nextTick(() => {
        this.selected_input = null;
      });
    },
    image_changed(prop, new_value, id) {
      this.update_option(prop, new_value, id);
    },
    update_option(prop, new_value, id) {
      const formData = new FormData();
      formData.append(prop, new_value);
      formData.append("id", id);
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/update_option`,
            method: "POST",
          },
          data: formData,
        })
        .then((res) => {
          if (prop == "image") {
            this.q.options[this.mcq_index].image = null;
            this.q.options[this.mcq_index].image_path = res.data.data.image;
          } else if (prop == "text") {
            this.check_value(new_value, id);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    sort_option_position(sort_type) {
      let currItem = this.questions[this.q_index].options.splice(
        this.mcq_index,
        1
      );
      let position =
        sort_type == "up" ? this.mcq_index - 1 : this.mcq_index + 1;
      this.questions[this.q_index].options.splice(position, 0, currItem[0]);
    },
    sort_option(type) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/sort_option`,
            method: "post",
          },
          data: {
            type: type,
            option_id: this.option.id,
          },
        })
        .then((res) => {
          this.sort_option_position(type);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  components: {
    RelatedAnswer,
  },
  created() {
    // console.log(this.$refs);
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field--enclosed .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer {
    margin-top: 3px !important;
  }
  .v-chip.v-size--default {
    font-size: 20px;
    height: 51px;
    width: 41px;
    text-align: center;
    padding: 0;
    display: block;
  }
  .file {
    .v-input__prepend-outer {
      position: relative;
      top: -10px;
      button {
        font-size: 35px;
      }
    }
  }
  .v-input--switch {
    background: #f0f0f0;
    height: 62px;
    margin-top: 0;
    padding: 18px 8px 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom: 1px solid #838383;
  }
}
</style>
